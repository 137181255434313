import React, { useState, useEffect } from "react";
import $ from "jquery";

const MacchinaDaScrivere = ({
  text,
  lastMessage,
  ButtonNavigation,
  setIsLoading,
}) => {
  const [displayElements, setDisplayElements] = useState([]);

  useEffect(() => {
    const elements = document.getElementsByClassName("buttonScelta");

    // Rimuovi gli event listener esistenti prima di aggiungerli nuovamente
    $(".buttonScelta").on("click", (event) => {
      ButtonNavigation(event.target.value);
    });
  }, [displayElements]);

  // Funzione per applicare gli stili al testo
  const applyStyles = (text) => {
    let newText = text.replace(/\*/g, "<strong>").replace(/\*/g, "</strong>"); // sostituisci * con tag <strong>
    newText = newText.replace(/\r\n/g, "<br></br>"); // sostituisci \n con tag <br>
    return newText;
  };

  const [isUserScrolling, setIsUserScrolling] = useState(false);

  useEffect(() => {
    // Funzione di gestione dell'evento di scorrimento del div "modal-body"
    const handleWheel = () => {
      setIsUserScrolling(true);
    };

    const modalBody = $(".modal-body")[0]; // Ottieni il primo elemento con classe "modal-body"

    // Aggiungi l'evento di wheel al div "modal-body"
    $(".modal-body").on("wheel", handleWheel);

    // Aggiungi l'evento di keydown al documento per le frecce direzionali
    $(document).on("keydown", (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        setIsUserScrolling(true);
      }
    });

    modalBody.scrollTop = modalBody.scrollHeight;

    // Controlla se l'utente sta scorrendo manualmente il div "modal-body"
    const interval = setInterval(() => {
      if (isUserScrolling) {
        $(".modal-body").stop(); // Interrompi l'animazione di scorrimento
      } else if (lastMessage) {
        setIsUserScrolling(false);
      }
    }, 100);

    // Pulisci l'intervallo quando l'effetto useEffect viene smontato
    return () => {
      clearInterval(interval);
      $(".modal-body").off("wheel", handleWheel);
    };
  }, [isUserScrolling ? "" : displayElements]); // Rimuovi la dipendenza displayElements

  useEffect(() => {
    const splittedArrays = text.split(/\[html\]|\[\/html\]/g);
    let i = 0;
    const intervalId = setInterval(() => {
      let newElements = [];
      splittedArrays.forEach((single) => {
        if (!single.startsWith("<")) {
          newElements.push(
            <div
              key={newElements.length}
              dangerouslySetInnerHTML={{
                __html: single
                  .slice(0, i)
                  .replace(/\n/g, "<br>")
                  .replace(/\*/g, "<strong>")
                  .replace(/\*/g, "</strong>"),
              }}
            ></div>
          );
        } else {
          // Traduci l'elemento HTML
          const tempElement = document.createElement("div");
          tempElement.innerHTML = single;
          const translatedHtml = applyStyles(tempElement.innerHTML);
          newElements.push(
            <span
              key={newElements.length}
              dangerouslySetInnerHTML={{ __html: applyStyles(translatedHtml) }}
            />
          );
        }
      });
      setDisplayElements(newElements);
      i++;

      if (i > text.length) {
        clearInterval(intervalId);

        $(".fade-element:not(.loaded)").each(function (index) {
          $(this)
            .delay(100 * index)
            .fadeIn(100, function () {
              $(this).addClass("loaded");
            });
        });

        $(".card:not(.loaded)").each(function (index) {
          $(this)
            .delay(100 * index)
            .fadeIn(100, function () {
              $(this).addClass("loaded");
            });
        });

        $(".card-body:not(.loaded)").each(function (index) {
          $(this)
            .delay(100 * index)
            .fadeIn(100, function () {
              $(this).addClass("loaded");
            });
        });

        $(".products-section:not(.loaded)").each(function (index) {
          $(this)
            .delay(100 * index)
            .fadeIn(100, function () {
              $(this).addClass("loaded");
            });
        });

        $(".stories-section:not(.loaded)").each(function (index) {
          $(this)
            .delay(100 * index)
            .fadeIn(100, function () {
              $(this).addClass("loaded");
            });
        });
      }
    }, 10); // tempo di attesa tra un carattere e l'altro
    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <div>
      {displayElements.map((element, index) => {
        if (typeof element === "string") {
          // Se l'elemento è una stringa, applica gli stili e renderizzala come HTML
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: applyStyles(element),
              }}
            />
          );
        } else {
          // Altrimenti, restituisci l'elemento React originale
          return React.cloneElement(element, { key: index });
        }
      })}
    </div>
  );
};

export default MacchinaDaScrivere;
