import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TestModalChatbot from "./components/TestModalChatbot";

function App() {
  return (
    <Routes>
      <Route path='/' element={<TestModalChatbot />} />
    </Routes>
  );
}

export default App;
