import React, { useState, useEffect, useRef } from "react";
import MacchinaDaScrivere from "./MacchinaDaScrivere";
import $ from "jquery";
import { io } from "socket.io-client";
import he from "he";

const TestModalChatbot = ({ content }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState("");
  const [jsonRasa, setJsonRasa] = useState("");
  const [informationIntent, setInformationIntent] = useState("");

  const [passEntities, setPassEntities] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //Classificazione intent
  const [intentClassification, setIntentClassification] = useState("");

  // Inizializza lo stato iniziale della sessione.
  const [sessionId, setSessionId] = useState(null);

  // Genera un nuovo ID di sessione quando l'utente accede alla chatbot per la prima volta.
  useEffect(() => {
    if (!sessionId) {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      localStorage.setItem("sessionId", newSessionId);
    }
  }, [sessionId]);

  const fakeUa = require("fake-useragent");

  const [nomeDelBot, setNomeDelBot] = useState("");
  const [entName, setEntName] = useState("");
  const [sitoWeb, setSitoWeb] = useState("");
  const [headFootColor, setHeadFootColor] = useState("");
  const [bodyColor, setBodyColor] = useState("");
  const [iconUserBg, setIconUserBg] = useState("");
  const [iconUserColor, setIconUserColor] = useState("");
  const [iconBotBg, setIconBotBg] = useState("");
  const [iconBotColor, setIconBotColor] = useState("");

  console.log(entName);
  console.log(iconBotColor);

  useEffect(() => {
    // Ottieni l'URL corrente
    const url = new URL(window.location.href);

    // Estrai i valori dei parametri
    const nomeDelBotParam = url.searchParams.get("nomeDelBot") || "Chatbot";
    const entNameParam = url.searchParams.get("entName") || "Azienda";
    const sitoWebParam =
      url.searchParams.get("siteLink") ||
      "https://query.cognitivesearch.fulgeas.com/";
    const headFootColorParam =
      url.searchParams.get("headFootColor") || "#DBDBDB";
    const bodyColorParam = url.searchParams.get("bodyColor") || "white";
    const iconUserBgParam = url.searchParams.get("iconUserBg") || "#6eb3d1";
    const iconUserColorParam = url.searchParams.get("iconUserColor") || "white";
    const iconBotBgParam = url.searchParams.get("iconBotBg") || "#e4013b";
    const iconBotColorParam = url.searchParams.get("iconBotColor") || "white";

    // Imposta i valori dei parametri negli state
    setNomeDelBot(nomeDelBotParam);
    setEntName(entNameParam);
    setSitoWeb(sitoWebParam);
    setHeadFootColor(headFootColorParam);
    setBodyColor(bodyColorParam);
    setIconUserBg(iconUserBgParam);
    setIconUserColor(iconUserColorParam);
    setIconBotBg(iconBotBgParam);
    setIconBotColor(iconBotColorParam);
  }, []);

  const err502message =
    "Ci scusiamo per l'inconveniente. Il server sta attualmente riscontrando un errore 502 e stiamo lavorando per risolvere il problema il prima possibile. Ti preghiamo di riprovare più tardi. Grazie per la tua pazienza e comprensione.";

  // Rimuovi l'ID di sessione dal localStorage quando l'utente effettua il refresh della pagina.
  function handleBeforeUnload() {
    localStorage.removeItem("sessionId");
  }

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [informationIntent]);

  // Registra l'evento beforeunload per gestire il refresh della pagina.
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Genera un nuovo ID di sessione.
  function generateSessionId() {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 100000);
    return `${timestamp}-${random}`;
  }

  const [clicked, setIsClicked] = useState(false);

  console.log(chatHistory);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsClicked(true);
      handleSendMessage();
    }
  };

  const [totalChat, setTotalChat] = useState([]);
  let userLastMessage = "";
  const handleSendMessage = () => {
    userLastMessage = message;
    setMessage("");
    let conversation = {
      userMessage: userLastMessage,
      botMessage: "Sto pensando ...",
    };
    setTotalChat((prevTotal) => [...prevTotal, conversation]);

    fetch(`${sitoWeb}?message=${message}&sessionId=${sessionId}`, {
      headers: {
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
    })
      .then((response) => {
        conversation.botMessage = "";

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const readChunk = () => {
          return reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }

            let chunk = decoder.decode(value, { stream: true });
            chunk = chunk.replace(/"/g, "");

            conversation.botMessage += chunk;
            setTotalChat((prevTotal) => [...prevTotal]); // Aggiorna lo stato di totalChat

            return readChunk();
          });
        };

        readChunk().catch((error) => {
          console.error("Errore durante la lettura dei chunk:", error);
        });
      })
      .catch((error) => {
        console.error("Errore durante la richiesta:", error);
      });
  };

  const modalBodyRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const handleScroll = () => {
    const modalBody = modalBodyRef.current;
    if (
      modalBody.scrollTop !==
      modalBody.scrollHeight - modalBody.offsetHeight
    ) {
      setIsUserScrolling(true);
    } else {
      setIsUserScrolling(false);
    }
  };

  useEffect(() => {
    const modalBody = modalBodyRef.current;
    if (!isUserScrolling) {
      modalBody.scrollTop = modalBody.scrollHeight;
    }
  }, [totalChat, isUserScrolling]);

  let url = "http://localhost:8080/connector-aim.php";
  const ButtonNavigation = async (message) => {
    let initalMessage = message.split(";");

    console.log(initalMessage[0]);
    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/api/get-model/abcdef

    console.log(systemResponse);

    try {
      const rasaResponse = await fetch(url, {
        method: "g",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          sessionId: sessionId,
          responseSite: sitoWeb,
        }),
      });

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: message,
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      } else {
        const rasaData = await rasaResponse.json();
        setJsonRasa(JSON.stringify(rasaData, null, 2));
        setInformationIntent(rasaData);

        let lastMessage = message;
        setMessage("");
        console.log(response);
        const chatMessage = {
          userMessage: lastMessage,
          systemResponse: rasaData,
        };

        setChatHistory((prevChatHistory) => [...prevChatHistory, chatMessage]);

        setPassEntities(true);
        console.log("io", rasaResponse);
        if (initalMessage[2]) {
          setPassEntities(false);
        }
      }
    } catch (error) {
      console.log(error);

      setResponse("Mi dispiace, c'è stato un errore");
    }
  };

  const [buttonClicked, setButtonClicked] = useState(false);

  const [systemResponse, setSystemResponse] = useState("");

  return (
    <>
      {/* Modale Chat */}

      <div
        className='modal fade show'
        style={{ background: "transparent" }}
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex=''
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog position-relative p-3 modalChatbot'>
          <div className='modal-content position-relative borderModal '>
            <div
              className='modal-header d-flex justify-content-between px-4'
              style={{ background: `${headFootColor}` }}
            >
              <h5 className='modal-title fs-6' id={"modalTitle"}>
                {nomeDelBot}
              </h5>
              <button
                type='button'
                className='btn-close text-reset buttonChatbot'
                data-bs-dismiss='offcanvas'
                data-bs-target='#offcanvasScrolling'
                aria-label='Close'
                id='closeButton'
              ></button>
            </div>

            <div
              className='modal-body text-dark text-start p-0'
              style={{ background: `${bodyColor}` }}
              id='modal-body'
              ref={modalBodyRef}
              onScroll={handleScroll}
            >
              <div className='row d-flex align-items-center p-0 m-0 message-chat'>
                <div className='col-1 ms-2 ms-sm-0 d-flex justify-content-center p-0 bot-cont'>
                  <div
                    className='rounded-circle bg-red icon-bot'
                    style={{
                      backgroundColor: `${iconBotBg}`,
                      color: `${iconBotColor}`,
                    }}
                  >
                    <i className='bi bi-chat-dots-fill'></i>
                  </div>
                </div>
                <div className='col-10 ms-2 ms-sm-0 col-sm-11 p-0 fs-6 bot-first-message'>
                  <MacchinaDaScrivere
                    text={`Ciao, sono l'assistente virtuale di ${entName}! Come posso aiutarti?`}
                    setIsLoading={setIsLoading}
                  ></MacchinaDaScrivere>
                </div>
              </div>
              <div
                className={`col-11 offset-1 loading d-none p-0 d-flex gap-2 my-3`}
              >
                <span
                  className='spinner-grow spinner-grow-sm'
                  style={{ backgroundColor: `${iconBotBg}` }}
                  role='status'
                  aria-hidden='true'
                ></span>
                <span
                  className='spinner-grow spinner-grow-sm'
                  style={{ backgroundColor: `${iconBotBg}` }}
                  role='status'
                  aria-hidden='true'
                ></span>
                <span
                  className='spinner-grow spinner-grow-sm'
                  style={{ backgroundColor: `${iconBotBg}` }}
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>

              {totalChat.map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='row bg-grey-message d-flex align-items-center p-0 m-0 message-chat'>
                      <div className='col-1 ms-2 ms-sm-0 d-flex justify-content-center p-0 bot-cont'>
                        <div
                          className='rounded-circle bg-light-blue icon-bot'
                          style={{
                            backgroundColor: `${iconUserBg}`,
                            color: `${iconUserColor}`,
                          }}
                        >
                          <i className='bi bi-search p-0'></i>
                        </div>
                      </div>
                      <div className='col-10 ms-2 ms-sm-0 col-sm-11 p-0 fs-6 bot-first-message'>
                        {row.userMessage}
                      </div>
                    </div>

                    <>
                      <div className='text-dark text-start p-0 bg-white '>
                        <div
                          className='row d-flex align-items-start py-4 p-0 m-0 message-chat chat'
                          style={{ background: `${bodyColor}` }}
                        >
                          <div className='col-1 ms-2 ms-sm-0 d-flex justify-content-center p-0 bot-cont'>
                            <div
                              className='rounded-circle bg-red icon-bot'
                              style={{
                                backgroundColor: `${iconBotBg}`,
                                color: `${iconBotColor}`,
                              }}
                            >
                              <i className='bi bi-chat-dots-fill'></i>
                            </div>
                          </div>
                          <div className='col-10 ms-2 ms-sm-0 col-sm-11 p-0 fs-6 bot-first-message'>
                            <p className='m-0'>{row.botMessage}</p>
                          </div>
                          <div
                            className={`col-11 offset-1 loading d-none p-0 d-flex gap-2`}
                          >
                            <span
                              className='spinner-grow spinner-grow-sm'
                              style={{ backgroundColor: `${iconBotBg}` }}
                              role='status'
                              aria-hidden='true'
                            ></span>
                            <span
                              className='spinner-grow spinner-grow-sm'
                              style={{ backgroundColor: `${iconBotBg}` }}
                              role='status'
                              aria-hidden='true'
                            ></span>
                            <span
                              className='spinner-grow spinner-grow-sm'
                              style={{ backgroundColor: `${iconBotBg}` }}
                              role='status'
                              aria-hidden='true'
                            ></span>
                          </div>

                          {/* Risposta del bot  */}
                        </div>
                      </div>
                    </>
                  </React.Fragment>
                );
              })}

              {/* <p>
                {prova !== null
                  ? prova.map((item) => he.decode(item.replace(/"/g, "")))
                  : ""}
              </p> */}

              <div
                className='last-div py-3'
                ref={scrollRef}
                style={{ background: `${bodyColor}` }}
              ></div>
            </div>

            <div
              className='divisore-input'
              style={{ background: `${bodyColor}` }}
            ></div>
            <div
              className='modal-footer d-flex justify-content-center position-sticky bottom-0'
              style={{ background: `${headFootColor}` }}
            >
              {/* <div className='chat-footer position-relative mt-0'>
                        <input
                          type='text'
                          className='rounded-pill chat-input'
                          placeholder='Cerca tra espositori, prodotti, espositori e storie'
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <div className='chat-send-button'>
                          <a
                            className='btn btn-dark rounded-circle'
                            type='button'
                            onClick={handleSendMessage}
                          >
                            {" "}
                            <i className='bi bi-search p-0'></i>
                          </a>
                        </div>
                      </div> */}

              <div className='chat-footer d-flex justify-content-center align-items-center'>
                <div className='form-group has-search position-relative'>
                  <a
                    className=' form-control-feedback p-0 pe-auto icon-search-container'
                    onClick={handleSendMessage}
                  >
                    <i
                      className='bi bi-search bg-light-blue rounded-circle search-icon '
                      style={{
                        backgroundColor: `${iconUserBg}`,
                        color: `${iconUserColor}`,
                      }}
                    ></i>
                  </a>
                  <input
                    type='text'
                    className='form-control search-bar rounded-pill'
                    placeholder={`Invia un messaggio a ${nomeDelBot}`}
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestModalChatbot;
